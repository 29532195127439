<template>
  <div class="payment">
    <h1> This is payment </h1>
  </div>
</template>
<script>
  export default{
    name: 'PaymentPage,'
  };
</script>
